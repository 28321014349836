import { CardGenerate, cardTitle, FilterHandler } from '../../components';
import { ARCHIVE_URL } from '../../constants';

export class Archive extends FilterHandler {
  /**
   * @type {number}
   */
  count = 8;
  /**
   * @type {number}
   */
  totalCount = 0;
  constructor() {
    super('#archive-filter', ARCHIVE_URL, '.archive-list');
    if (!this.form) {
      return;
    }
    this.loadMoreInit();

    if (this.countInput) {
      this.count = parseInt(this.countInput.value, 10);
    }
  }

  handleResponse(data) {
    this.loadMoreButton?.classList.remove('is-loading');
    this.container.innerHTML = '';
    this.totalCount = data.totalCount;
    data.data.forEach((item) => {
      const card = new CardGenerate(item, 'archive');
      this.container.append(card.getCard());
    });
    cardTitle.init();
    this.checkLoadMore(this.count, this.totalCount);
  }

  loadMoreInit() {
    this.loadMoreButton?.addEventListener('click', (e) => {
      e.preventDefault();
      this.count += 8;
      this.countInput.value = this.count.toString(10);
      this.form.dispatchEvent(new Event('submit'));
    });
  }
}
