import { validatorEmail } from '../utils';

class Footer {
  init() {
    const input = document.querySelector('.footer-subscribe__input');
    const field = document.querySelector('.footer-subscribe__field');
    if (!input) {
      return;
    }

    input.addEventListener('input', () => {
      if (input.scrollWidth > input.clientWidth) {
        field.classList.add('footer-subscribe__field_oversize');
      } else {
        field.classList.remove('footer-subscribe__field_oversize');
      }
    });

    this.formSubmit();
  }

  formSubmit() {
    const form = document.querySelector('#footer-subscribe-form');
    if (!form) return;

    const emailElement = form.querySelector('#footer-subscribe__email');
    const emailElementError = emailElement.querySelector(
      '.footer-subscribe__error'
    );
    const policyElement = form.querySelector('#footer-subscribe__policy');
    this.clearErrors([emailElement, policyElement]);

    form.addEventListener('submit', (e) => {
      const formData = new FormData(form);

      const email = formData.get('email').trim();
      const policyChecked = formData.get('policy') === 'on';

      if (!email) {
        emailElement.classList.add('invalid');
        if (emailElementError) {
          emailElementError.textContent = 'Поле не может быть пустым';
        }
        e.preventDefault();
      }

      if (email && !validatorEmail(email)) {
        emailElement.classList.add('invalid');
        if (emailElementError) {
          emailElementError.textContent = 'Введен некорректный email';
        }
        e.preventDefault();
      }

      if (!policyChecked) {
        policyElement.classList.add('invalid');
        e.preventDefault();
      }
    });

    emailElement.addEventListener('change', (e) => {
      this.clearErrors([emailElement]);
    });

    policyElement.addEventListener('change', (e) => {
      this.clearErrors([policyElement]);
    });
  }

  /**
   *
   * @param elements {Element[]}
   */
  clearErrors(elements) {
    elements.forEach((el) => {
      el.classList.remove('invalid');
    });
  }
}

export const footer = new Footer();
