import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class HomeBackground {
  pageBackgroundTo;
  pageBackgroundTo2;
  init() {
    gsap.registerPlugin(ScrollTrigger);

    const pageBackground = document.querySelector('.home-page');
    const header = document.querySelector('.header');
    const search = document.querySelector('.search');

    gsap.set(pageBackground, { backgroundColor: '#000000' });

    this.pageBackgroundTo = gsap.to(pageBackground, {
      scrollTrigger: {
        id: 'home-museum',
        trigger: '.home-museum',
        start: 'top 50%',
        end: 'top 30%',
        markers: false,
        scrub: true,
        onEnter: () => {
          pageBackground.classList.add('invert');
          header.classList.add('invert');
          search.classList.add('invert');
        },
        onLeaveBack: () => {
          pageBackground.classList.remove('invert');
          header.classList.remove('invert');
          search.classList.remove('invert');
        },
      },
      immediateRender: !1,
      backgroundColor: '#ffffff',
    });

    this.pageBackgroundTo2 = gsap.to(pageBackground, {
      scrollTrigger: {
        id: 'home-events',
        trigger: '.home-events',
        start: 'top 50%',
        end: 'top 30%',
        markers: false,
        scrub: true,
        onEnter: () => {
          pageBackground.classList.remove('invert');
          header.classList.remove('invert');
          search.classList.remove('invert');
        },
      },
      immediateRender: !1,
      backgroundColor: '#000000',
    });
  }

  refreshScrollTrigger() {
    if (this.pageBackgroundTo) {
      this.pageBackgroundTo.scrollTrigger.refresh();
    }
    if (this.pageBackgroundTo2) {
      this.pageBackgroundTo2.scrollTrigger.refresh();
    }
  }
}

export const homeBackground = new HomeBackground();
