import { slideDown, slideUp } from '../utils';
import { homeBackground } from '../pages/home/home-background';

class EventsAccordion {
  init() {
    document.querySelectorAll('.events-accordion').forEach((el, i) => {
      if (i === 0) {
        this.openAccordion(el);
      }
      el.querySelector('.events-accordion__head').onclick = () => {
        this.openAccordion(el);
      };
    });
  }

  /**
   *
   * @param accordion {HTMLElement}
   */
  openAccordion(accordion) {
    if (accordion.classList.contains('events-accordion_active')) {
      this.hideAccordion(accordion);
    } else {
      const active = accordion
        .closest('.events-calendar__list')
        .querySelector('.events-accordion_active');
      if (active) {
        this.hideAccordion(active);
      }
      accordion.classList.add('events-accordion_active');
      slideDown(
        accordion.querySelector('.events-accordion__body'),
        500,
        'block',
        () => {
          homeBackground.refreshScrollTrigger();
        }
      );
    }
  }

  /**
   *
   * @param element {HTMLBRElement}
   */
  hideAccordion(element) {
    element.classList.remove('events-accordion_active');
    slideUp(element.querySelector('.events-accordion__body'), 500, () => {
      homeBackground.refreshScrollTrigger();
    });
  }
}

export const eventsAccordion = new EventsAccordion();
