import { slideToggle } from '../../utils';

class EventMore {
  init() {
    const eventMore = document.querySelector('.event-more');
    if (!eventMore) {
      return;
    }

    const button = eventMore.querySelector('.event-more__button');

    button.addEventListener('click', () => {
      eventMore.classList.toggle('event-more_active');
      slideToggle(eventMore.querySelector('.event-more__content'), 500);
      button.textContent = eventMore.classList.contains('event-more_active')
        ? 'Скрыть'
        : 'Подробнее';
    });
  }
}

export const eventMore = new EventMore();
