class ScrollButtons {
  constructor() {}

  init() {
    this.scrollUpButton = document.querySelector('.scroll-buttons__up');
    this.scrollDownButton = document.querySelector('.scroll-buttons__down');
    this.scrollAmount = document.body.scrollHeight * 0.3;

    this.updateButtonStates = this.updateButtonStates.bind(this);
    this.handleScrollUp = this.handleScrollUp.bind(this);
    this.handleScrollDown = this.handleScrollDown.bind(this);

    if (this.scrollUpButton && this.scrollDownButton) {
      this.scrollUpButton.addEventListener('click', this.handleScrollUp);
      this.scrollDownButton.addEventListener('click', this.handleScrollDown);

      this.updateButtonStates();

      window.addEventListener('scroll', this.updateButtonStates);
    }
  }

  updateButtonStates() {
    this.scrollUpButton.disabled = window.scrollY === 0;
    this.scrollDownButton.disabled =
      window.scrollY + window.innerHeight >= document.body.scrollHeight;
  }

  handleScrollUp() {
    if (this.scrollUpButton.disabled) {
      return;
    }
    window.scrollBy({
      top: -this.scrollAmount,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => this.updateButtonStates(), 500);
  }

  handleScrollDown() {
    if (this.scrollDownButton.disabled) {
      return;
    }
    window.scrollBy({
      top: this.scrollAmount,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => this.updateButtonStates(), 500);
  }
}

export const scrollButtons = new ScrollButtons();
