import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobileDevice, isMobileWidth } from '../../utils';
class HomeBarrel {
  animated = false;
  init() {
    gsap.registerPlugin(ScrollTrigger);
    const barrel = document.querySelector('.home-barrel');
    const video = document.querySelector('.home-hero__background');

    const start = gsap.fromTo(
      barrel,
      { rotateX: -150 },
      {
        delay: 1,
        duration: 1,
        rotateX: -10,
      }
    );

    window.addEventListener('scroll', () => {
      if (window.scrollY > 0 && !this.animated) {
        start.pause();
        this.animated = true;
        let end = '30%';
        if (isMobileWidth()) {
          end = '40%';
        }

        gsap.fromTo(
          barrel,
          {
            rotateX: -10,
          },
          {
            scrollTrigger: {
              id: 'home-barrel',
              trigger: '.home-hero',
              start: 'top top',
              end: `bottom ${end}`,
              markers: false,
              scrub: true,
            },
            immediateRender: !1,
            rotateX: 120,
          }
        );
      }
    });

    gsap.to(video, {
      scrollTrigger: {
        id: 'home-video',
        trigger: '.home-hero',
        start: '30% top',
        end: '80% 20%',
        markers: false,
        scrub: true,
      },
      opacity: 0,
    });
  }
}

export const homeBarrel = new HomeBarrel();
