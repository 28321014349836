class Header {
  init() {
    const header = document.querySelector('.header');
    const search = document.querySelector('.search');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 40) {
        header?.classList.add('header_fixed');
        search?.classList.add('search_fixed');
      } else {
        header?.classList.remove('header_fixed');
        search?.classList.remove('search_fixed');
      }
    });
  }
}

export const header = new Header();
