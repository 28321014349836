class EventTabs {
  init(container) {
    const list = container.querySelectorAll('.event-tabs__list');
    if (!list) {
      return;
    }

    list.forEach((listItem) => {
      listItem.querySelectorAll('.event-tabs__item').forEach((el, i) => {
        if (i === 0) {
          this.setActiveTab(el, listItem, container);
        }

        el.onclick = (e) => {
          e.preventDefault();

          this.setActiveTab(el, listItem, container);
        };
      });
    });
  }

  /**
   *
   * @param tab {HTMLElement}
   * @param list {HTMLElement}
   * @param container {HTMLElement}
   */
  setActiveTab(tab, list, container) {
    list
      .querySelector('.event-tabs__item_active')
      ?.classList.remove('event-tabs__item_active');
    container
      .querySelector('.event-tabs__pane_active')
      ?.classList.remove('event-tabs__pane_active');
    tab.classList.add('event-tabs__item_active');
    const price = list.querySelector('.event-tabs__price');
    if (price) {
      const dataPrice = tab.dataset.price;
      price.textContent = dataPrice || '';
    }

    const pane = container.querySelector('#' + tab.dataset.tab);
    if (pane) {
      pane.classList.add('event-tabs__pane_active');
    }
  }
}

export const eventTabs = new EventTabs();
