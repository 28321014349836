import { slideDown, slideUp } from '../utils';
import { homeBackground } from '../pages/home/home-background';

class Faq {
  init() {
    document.querySelectorAll('.faq__item').forEach((el, i) => {
      el.querySelector('.faq__head').onclick = () => {
        this.openAccordion(el);
      };
    });
  }

  /**
   *
   * @param accordion {HTMLElement}
   */
  openAccordion(accordion) {
    if (accordion.classList.contains('faq__item_active')) {
      this.hideAccordion(accordion);
    } else {
      const active = accordion
        .closest('.faq')
        .querySelector('.faq__item_active');
      if (active) {
        this.hideAccordion(active);
      }
      accordion.classList.add('faq__item_active');
      slideDown(accordion.querySelector('.faq__body'), 500, 'block', () => {
        homeBackground.refreshScrollTrigger();
      });
    }
  }

  /**
   *
   * @param element {HTMLBRElement}
   */
  hideAccordion(element) {
    element.classList.remove('faq__item_active');
    slideUp(element.querySelector('.faq__body'));
  }
}

export const faq = new Faq();
