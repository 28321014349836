import { getHTML, isTabletWidth } from '../utils';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { gsap } from 'gsap';
import { isKiosk } from '../utils/is-kiosk.util';
import { popup } from './popup';

class PopupPage {
  /**
   * @type {HTMLElement}
   */
  loader;
  init() {
    this.eventsInit();
    this.toursInit();
    this.ticketInit();
  }

  eventsInit() {
    const popup = document.querySelector('#popup-event');
    if (!popup) {
      return;
    }
    document
      .querySelectorAll(
        '.event-card__image, .event-card__title, .event-card__link'
      )
      .forEach((el) => {
        el.onclick = (e) => {
          e.preventDefault();
          this.loadPage(popup, el.dataset.link);
          this.setTicketLink(popup, el.dataset.ticket);
        };
      });
  }

  toursInit() {
    const popup = document.querySelector('#popup-tour');
    if (!popup) {
      return;
    }

    document
      .querySelectorAll('.tour-card__image, .tour-card__title')
      .forEach((el) => {
        el.onclick = (e) => {
          e.preventDefault();
          this.loadPage(popup, el.dataset.link);
          this.setTicketLink(popup, el.dataset.ticket);
        };
      });
  }

  /**
   *
   * @param popup {HTMLElement}
   * @param link {string}
   */
  loadPage(popup, link) {
    if (!popup && !link) {
      return;
    }

    const container = popup.querySelector('.popup-page__content .container');

    if (!container) {
      return;
    }

    container.innerHTML = '';

    this.createLoader();
    container.append(this.loader);

    getHTML(link, (responseCode, responseData) => {
      this.removeLoader();
      if (responseCode === 200) {
        container.innerHTML = responseData;

        container.querySelectorAll('.event-navigation__item').forEach((el) => {
          el.onclick = (e) => {
            e.preventDefault();

            const loadLink = el.dataset.link;
            this.loadPage(popup, loadLink);
          };
        });

        this.tabsInit(container);
        this.sliderInit(container);
      } else {
        container.textContent = 'Ошибка при загрузке данных';
        console.error('Ошибка при загрузке данных');
      }
    });
  }

  createLoader() {
    const loader = document.createElement('div');
    loader.classList.add('loader');
    const icon = document.createElement('img');
    icon.src = 'assets/img/icons/loader.svg';
    loader.append(icon);

    this.loader = loader;
  }

  removeLoader() {
    this.loader.remove();
  }

  /**
   * @param popup {HTMLElement}
   * @param link {string}
   */
  setTicketLink(popup, link) {
    const button = popup.querySelector('.popup-page__head .button-buy-ticket');
    if (!button) {
      return;
    }

    if (link) {
      button.dataset.ticket = 'true';
      button.classList.remove('disabled');
    } else {
      button.dataset.ticket = 'false';
      button.classList.add('disabled');
    }
  }

  ticketInit() {
    document.querySelectorAll('.button-buy-ticket').forEach((button) => {
      button.onclick = (e) => {
        e.preventDefault();
        if (button.classList.contains('disabled')) {
          return;
        }

        if (typeof WPOpenModal === 'function') {
          WPOpenModal();
        }

        if (isKiosk()) {
          const qrPopup = document.querySelector('#popup-kiosk-qr');
          popup.open(qrPopup);

          setTimeout(() => {
            if (qrPopup.classList.contains('popup_show')) {
              popup.close(qrPopup);
            }
          }, 20000);
        }
      };
    });
  }

  /**
   *
   * @param container {HTMLElement}
   */
  tabsInit(container) {
    const list = container.querySelectorAll('.tour-info-tabs__list');
    if (!list) {
      return;
    }

    list.forEach((listItem) => {
      listItem.querySelectorAll('.tour-info-tabs__item').forEach((el, i) => {
        if (i === 0) {
          this.setActiveTab(el, listItem, container);
        }

        el.onclick = (e) => {
          e.preventDefault();

          this.setActiveTab(el, listItem, container);
        };
      });
    });
  }

  /**
   *
   * @param tab {HTMLElement}
   * @param list {HTMLElement}
   * @param container {HTMLElement}
   */
  setActiveTab(tab, list, container) {
    list
      .querySelector('.tour-info-tabs__item_active')
      ?.classList.remove('tour-info-tabs__item_active');
    container
      .querySelector('.tour-info-tabs__pane_active')
      ?.classList.remove('tour-info-tabs__pane_active');
    tab.classList.add('tour-info-tabs__item_active');
    const price = list.querySelector('.tour-info-tabs__price');
    if (price) {
      const dataPrice = tab.dataset.price;
      price.textContent = dataPrice || '';
    }

    const pane = container.querySelector('#' + tab.dataset.tab);
    if (pane) {
      pane.classList.add('tour-info-tabs__pane_active');
    }
  }

  /**
   *
   * @param container {HTMLElement}
   */
  sliderInit(container) {
    /**
     *
     * @type {HTMLElement}
     */
    const slider = container.querySelector('.tour-info-slider');
    if (!slider) {
      return;
    }

    Swiper.use([Navigation]);

    const next = slider.querySelector('.tour-info-slider__next');
    const prev = slider.querySelector('.tour-info-slider__prev');

    const swiper = new Swiper(slider.querySelector('.swiper'), {
      spaceBetween: 20,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      },
    });

    const cursor = container.querySelector('.tour-info-slider__cursor');

    if (!cursor || isTabletWidth()) {
      return;
    }

    slider.addEventListener('mouseenter', () => {
      cursor.classList.add('tour-info-slider__cursor_show');
    });

    slider.addEventListener('mouseleave', () => {
      cursor.classList.remove('tour-info-slider__cursor_show');
    });

    prev.addEventListener('mouseenter', () => {
      cursor.classList.remove('tour-info-slider__cursor_rotate');
    });

    next.addEventListener('mouseenter', () => {
      cursor.classList.add('tour-info-slider__cursor_rotate');
    });

    container.addEventListener('mousemove', (e) => {
      if (!isTabletWidth()) {
        gsap.to(cursor, {
          duration: 0.4,
          x: e.pageX - cursor.clientWidth / 2,
          y: e.clientY - cursor.clientHeight / 2,
        });
      }
    });
  }
}

export const popupPage = new PopupPage();
