import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import { Select } from './select';

export class DatePicker extends Select {
  /**
   * @type {AirDatepicker}
   */
  airDatePicker;
  /**
   * @type {HTMLInputElement}
   */
  hiddenInput;
  constructor(select) {
    super(select);
    this.hiddenInput = this.select.querySelector('input[type="hidden"]');
    this.initDatepicker();
  }

  initDatepicker() {
    const minDate = this.hiddenInput.dataset.mindate;
    this.airDatePicker = new AirDatepicker(this.hiddenInput, {
      range: true,
      multipleDatesSeparator: '-',
      inline: true,
      minDate: minDate ? minDate : new Date(),
      buttons: ['clear'],
      toggleSelected: false,
      onSelect: ({ date, formattedDate }) => {
        if (date.length === 2) {
          this.closeDropdown();
          this.hiddenInput.form?.dispatchEvent(new Event('submit'));
        }
        this.selectText.textContent =
          formattedDate.join('-') || this.defaultSelectText;
      },
    });
  }

  setValue(value) {
    const dates = [];
    value.split('-').forEach((date) => {
      const dateParts = date.split('.');
      const year = parseInt(dateParts[2]);
      const month = parseInt(dateParts[1]) - 1;
      const day = parseInt(dateParts[0]);
      dates.push(new Date(year, month, day));
    });
    this.airDatePicker.selectDate(dates, { silent: true });
    this.selectText.textContent = value;
    this.hiddenInput.value = value;
  }
}
