import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isSmallerWidth, isTabletWidth } from '../../utils';

class Hero {
  init() {
    gsap.registerPlugin(ScrollTrigger);

    const hero = document.querySelector('.about-hero');
    const imageY = [60, -70, -80];
    let insideY = -80;
    if (isSmallerWidth(1200)) {
      insideY = -30;
    }

    document.querySelectorAll('.about-hero__image').forEach((item, i) => {
      const img = item.querySelector('img');
      gsap.to(img, {
        scrollTrigger: {
          trigger: item,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          markers: false,
        },
        translateY: insideY,
      });

      gsap.to(item, {
        scrollTrigger: {
          trigger: hero,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
          markers: false,
        },
        translateY: imageY[i],
      });
    });
  }
}

export const aboutHero = new Hero();
