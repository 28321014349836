import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class Images {
  init() {
    gsap.registerPlugin(ScrollTrigger);

    document
      .querySelectorAll('.about-info__image, .about-exhibits__image')
      .forEach((item) => {
        const img = item.querySelector('img');
        gsap.to(img, {
          scrollTrigger: {
            trigger: item,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
            markers: false,
          },
          translateY: -180,
        });
      });
  }
}

export const images = new Images();
