import {
  AudioPlayer,
  CardGenerate,
  cardTitle,
  FilterHandler,
} from '../../components';
import { MEDIA_URL } from '../../constants';

export class Media extends FilterHandler {
  /**
   *
   * @type {[{player: AudioPlayer, status: 'play' | 'pause'}]}
   */
  players = [];
  constructor() {
    super('#media-filters', MEDIA_URL, '.media-list');
  }

  handleResponse(data) {
    this.container.innerHTML = '';
    data.data.forEach((item) => {
      const card = new CardGenerate(item, 'media');
      this.container.append(card.getCard());
    });
    cardTitle.init();
    this.audioPlayerInit();
  }

  audioPlayerInit() {
    this.players = [];
    document.querySelectorAll('.audio-player').forEach((el, i) => {
      const player = new AudioPlayer(el);
      this.players.push({
        player,
        status: 'pause',
      });
      player.on('onPlay', () => {
        this.checkPlayed();
        this.players[i].status = 'play';
      });
      player.on('onPause', () => {
        this.players[i].status = 'pause';
      });
    });
  }

  checkPlayed() {
    this.players.forEach((el) => {
      if (el.status === 'play') {
        el.player.pause();
      }
    });
  }
}
