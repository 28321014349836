import {
  CardGenerate,
  cardTitle,
  FilterHandler,
  tickets,
} from '../../components';
import { EXHIBITIONS_URL } from '../../constants';

export class Exhibitions extends FilterHandler {
  constructor() {
    super('#exhibitions-filter', EXHIBITIONS_URL, '.events-list');
  }

  handleResponse(data) {
    this.container.innerHTML = '';
    data.data.forEach((item) => {
      const card = new CardGenerate(item, 'events');
      this.container.append(card.getCard());
    });
    cardTitle.init();
    tickets.init();
  }
}
