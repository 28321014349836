export class VideoPlayer {
  constructor(player) {
    this.player = player;
    this.init();
  }

  init() {
    const iframe = this.player.querySelector('.event-video__iframe');
    const cover = this.player.querySelector('.event-video__cover');

    cover.onclick = () => {
      cover.style.display = 'none';
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        '*'
      );
    };
  }
}
