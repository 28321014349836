import { isKiosk } from '../utils/is-kiosk.util';
import { popup } from './popup';

class Tickets {
  init() {
    if (typeof WPInit === 'function') {
      WPInit({
        saletermstype: 'checkbox',
        onlytab: 'service',
        id: 771,
        width: (window.innerWidth / 2) | 700,
        height: (window.innerHeight / 2) | 480,
        type: 'modal',
      });
    }

    document.querySelectorAll('.button-buy-ticket').forEach((button) => {
      button.onclick = (e) => {
        e.preventDefault();
        if (button.classList.contains('disabled')) {
          return;
        }

        if (typeof WPOpenModal === 'function') {
          WPOpenModal();
        }

        if (isKiosk()) {
          const qrPopup = document.querySelector('#popup-kiosk-qr');
          popup.open(qrPopup);

          setTimeout(() => {
            if (qrPopup.classList.contains('popup_show')) {
              popup.close(qrPopup);
            }
          }, 20000);
        }
      };
    });
  }
}

export const tickets = new Tickets();
