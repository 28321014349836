import { isMobileWidth } from '../../utils';

class AboutAuthor {
  /**
   * @type {NodeListOf<HTMLElement>}
   */
  aboutAuthorsCredits;
  init() {
    this.aboutAuthorsCredits = document.querySelectorAll(
      '.about-authors-credits'
    );
    if (isMobileWidth() && this.aboutAuthorsCredits.length) {
      this.aboutAuthorsCredits.forEach((el) => {
        el.onclick = (e) => {
          e.preventDefault();

          if (
            e.target.closest('.about-authors-credits__close') ||
            el.classList.contains('active')
          ) {
            return;
          }

          document.body.classList.add('overflow-hidden');
          el.classList.add('active');
        };

        el.querySelector('.about-authors-credits__close').onclick = (e) => {
          e.preventDefault();

          if (el.classList.contains('active')) {
            document.body.classList.remove('overflow-hidden');
            el.classList.remove('active');
          }
        };
      });
    }
  }
}

export const aboutAuthor = new AboutAuthor();
