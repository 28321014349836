class HomeHeroHeight {
  init() {
    const homeHero = document.querySelector('.home-hero');
    const homePage = document.querySelector('.home-page');

    // this.setHeight(homeHero, homePage);

    window.addEventListener('resize', () => {
      // this.setHeight(homeHero, homePage);
    });
  }

  /**
   *
   * @param homeHero {HTMLElement}
   * @param homePage {HTMLElement}
   */

  setHeight(homeHero, homePage) {
    homePage.style.paddingTop = `${window.innerHeight}px`;
    homeHero.style.height = `${window.innerHeight}px`;
  }
}

export const homeHeroHeight = new HomeHeroHeight();
