import { isTabletWidth } from '../utils';
import { burger } from './burger';
import { SEARCH_URL } from '../constants';

class Search {
  /**
   * @type {HTMLInputElement}
   */
  input;
  /**
   * @type {HTMLButtonElement}
   */
  button;
  /**
   * @type {NodeListOf<HTMLButtonElement>}
   */
  headerSearch;
  /**
   * @type {HTMLElement}
   */
  search;
  /**
   * @type {HTMLElement}
   */
  list;
  /**
   * @type {HTMLElement}
   */
  loading;
  /**
   * @type {HTMLElement}
   */
  totalCount;
  /**
   * @type {string}
   */
  url = SEARCH_URL;

  init() {
    this.search = document.querySelector('.search');
    if (!this.search) {
      return;
    }
    this.input = document.querySelector('.search__input');
    this.button = document.querySelector('.search__button');
    this.headerSearch = document.querySelectorAll('.header__search');
    this.list = document.querySelector('.search-list');
    this.totalCount = document.querySelector('.search-total__count');
    this.generateLoading();
    this.initSearch();
    this.initToggleSearch();
  }

  initToggleSearch() {
    this.headerSearch.forEach((el) => {
      el.addEventListener('click', () => {
        if (this.search.classList.contains('show')) {
          this.searchClose();
        } else {
          this.searchOpen();
        }
      });
    });
  }

  searchOpen() {
    document.body.classList.add('overflow-hidden');
    this.headerSearch.forEach((el) => {
      el.classList.add('active');
    });
    this.search.classList.add('show');
    if (isTabletWidth()) {
      burger.hideNavigation();
    }
  }

  searchClose() {
    document.body.classList.remove('overflow-hidden');
    this.headerSearch.forEach((el) => {
      el.classList.remove('active');
    });
    this.search.classList.remove('show');
    this.input.value = '';
    this.list.innerHTML = '';
  }

  initSearch() {
    this.input.addEventListener('keyup', (event) => {
      event.preventDefault();
      if (event.code !== 'Enter' || !this.input.value) {
        return;
      }
      this.submitRequest();
    });

    this.button.addEventListener('click', (event) => {
      event.preventDefault();
      if (this.input.value) {
        this.submitRequest();
      }
    });
  }

  submitRequest() {
    this.list.append(this.loading);
    const url = `${this.url}?query=${this.input.value}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return this.handleResponse(data);
      })
      .catch((error) => console.error('Ошибка при выполнении запроса:', error));
  }

  /**
   *
   * @param data {{totalCount: number, data: {title: string, text: string, link: string}[]}}
   */
  handleResponse(data) {
    this.list.innerHTML = '';
    this.list.append(this.generateTotal(data.totalCount));
    data.data.forEach((item) => {
      this.list.append(this.generateSearchItem(item));
    });
  }

  generateLoading() {
    const loading = document.createElement('div');
    loading.classList.add('loading');
    const icon = document.createElement('img');
    icon.src = '../../assets/img/icons/loading.svg';
    loading.append(icon);
    this.loading = loading;
  }

  generateTotal(data) {
    const totalElement = document.createElement('div');
    totalElement.classList.add('search-total');

    const countElement = document.createElement('span');
    countElement.classList.add('search-total__count');
    countElement.textContent = data;

    totalElement.appendChild(countElement);
    totalElement.appendChild(document.createTextNode(' результатов'));

    return totalElement;
  }

  /**
   * @param data {{title: string, text: string, link: string}}
   * @return {HTMLAnchorElement}
   */
  generateSearchItem(data) {
    const item = document.createElement('a');
    item.classList.add('search-list__item');
    item.href = data.link;

    const title = document.createElement('span');
    title.classList.add('search-list__title');
    title.textContent = data.title;
    item.append(title);

    const text = document.createElement('span');
    text.classList.add('search-list__text');
    text.textContent = data.text;
    item.append(text);

    return item;
  }
}

export const search = new Search();
