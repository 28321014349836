import {
  CardGenerate,
  cardTitle,
  eventsAccordion,
  FilterHandler,
  tickets,
} from '../../components';
import { EVENTS_URL } from '../../constants';

export class Events extends FilterHandler {
  constructor() {
    super('#events-filter', EVENTS_URL, '.events-calendar');
  }

  handleResponse(data) {
    this.container.innerHTML = '';
    this.generateCalendar(data);
    eventsAccordion.init();
    cardTitle.init();
    tickets.init();
  }

  generateCalendar({ data }) {
    data.forEach(({ month, days }) => {
      const calendarItem = document.createElement('div');
      calendarItem.classList.add('events-calendar__item');

      const monthName = document.createElement('div');
      monthName.classList.add('events-calendar__month');
      monthName.textContent = month;
      calendarItem.append(monthName);

      const list = document.createElement('div');
      list.classList.add('events-calendar__list');
      calendarItem.append(list);

      days.forEach(({ day, cards }) => {
        const accordion = this.generateAccordionItem(day);
        list.append(accordion);

        const accordionBody = document.createElement('div');
        accordionBody.classList.add('events-accordion__body');
        accordion.append(accordionBody);

        const eventsAccordionList = document.createElement('div');
        eventsAccordionList.classList.add('events-accordion__list');
        accordionBody.append(eventsAccordionList);

        cards.forEach((card) => {
          const eventCard = new CardGenerate(card, 'events');
          eventsAccordionList.append(eventCard.getCard());
        });
      });

      this.container.append(calendarItem);
    });
  }

  generateAccordionItem(day) {
    const accordion = document.createElement('div');
    accordion.classList.add('events-accordion');

    const accordionHead = document.createElement('div');
    accordionHead.classList.add('events-accordion__head');
    accordion.append(accordionHead);

    const accordionTitle = document.createElement('div');
    accordionTitle.classList.add('events-accordion__title');
    accordionTitle.textContent = day;

    const accordionIcon = document.createElement('div');
    accordionIcon.classList.add('events-accordion__icon');
    accordionHead.append(accordionTitle, accordionIcon);

    return accordion;
  }
}
