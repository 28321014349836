class MediaPage {
  init() {
    const mediaPage = document.querySelector('.media-item-page');
    if (!mediaPage) {
      return;
    }

    document.querySelector('.header').classList.add('invert');
    document.querySelector('.search').classList.add('invert');
  }
}

export const mediaPage = new MediaPage();
