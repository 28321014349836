import { eventTabs } from './event-tabs';
import { eventMore } from './event-more';
import { eventSlider } from './event-slider';
import { tickets } from '../../components';
import { eventAudio } from './event-audio';
import { eventVideo } from './event-video';

class EventPage {
  init() {
    const eventPage = document.querySelector('.event-page');
    if (!eventPage) {
      return;
    }

    document.querySelector('.header').classList.add('invert');
    document.querySelector('.search').classList.add('invert');
    eventTabs.init(eventPage);
    eventMore.init();
    eventSlider.init();
    tickets.init();
    eventAudio.init();
    eventVideo.init();
  }
}

export const eventPage = new EventPage();
