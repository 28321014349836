import { VideoPlayer } from '../../components/video-player';

class EventVideo {
  init() {
    document.querySelectorAll('.event-video').forEach((el) => {
      new VideoPlayer(el);
    });
  }
}

export const eventVideo = new EventVideo();
