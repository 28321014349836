import { timeline } from './timeline';

class FilmStudio {
  init() {
    if (!document.querySelector('.film-studio-page')) {
      return;
    }
    timeline.init();
  }
}

export const filmStudio = new FilmStudio();
