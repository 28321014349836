export class Select {
  /**
   * @type {HTMLElement}
   */
  select;
  /**
   * @type {HTMLElement}
   */
  dropdown;
  /**
   *
   * @type {'checkbox' | 'radio'}
   */
  selectType;
  /**
   * @type {EventListener}
   */
  selectClickHandler;
  /**
   * @type {EventListener}
   */
  outsideClickHandler;
  /**
   * @type {HTMLElement}
   */
  selectText;
  /**
   * @type {string}
   */
  defaultSelectText;
  constructor(select) {
    this.select = select;
    this.selectType = this.select.dataset.type;
    this.dropdown = this.select.querySelector('.select__dropdown');
    this.selectText = this.select.querySelector('.select__text');
    this.defaultSelectText = this.selectText.textContent;
    this.init();
  }

  init() {
    this.selectClickHandler = this.selectClick.bind(this);
    this.select.addEventListener('click', this.selectClickHandler);
    this.outsideClickHandler = this.outsideClick.bind(this);
  }

  /**
   *
   * @private
   * @param e {MouseEvent}
   */
  selectClick(e) {
    e.preventDefault();

    if (e.target.closest('.select__dropdown')) {
      return;
    }

    if (this.dropdown.classList.contains('select__dropdown_open')) {
      this.closeDropdown();
      return;
    }

    this.openDropdown();
  }

  /**
   * @public
   */
  openDropdown() {
    this.select.classList.add('select_open');
    document.body.append(this.dropdown);

    this.dropdown.style.left = `${this.select.offsetLeft}px`;
    this.dropdown.style.top = `${
      this.select.offsetTop + this.select.clientHeight
    }px`;
    this.dropdown.style.width = `${this.select.clientWidth}px`;
    this.dropdown.classList.add('select__dropdown_open');

    document.addEventListener('click', this.outsideClickHandler);
  }

  /**
   * @public
   */
  closeDropdown() {
    this.select.classList.remove('select_open');
    this.dropdown.classList.remove('select__dropdown_open');
    this.select.append(this.dropdown);
    this.dropdown.removeAttribute('style');
    document.removeEventListener('click', this.outsideClickHandler);
  }

  /**
   * @private
   * @param e {MouseEvent}
   */
  outsideClick(e) {
    if (this.select.contains(e.target) || this.dropdown.contains(e.target)) {
      return;
    }

    this.closeDropdown();
  }

  /**
   * @public
   */
  destroy() {
    this.select.removeEventListener('click', this.selectClickHandler);
    document.removeEventListener('click', this.outsideClickHandler);
  }
}
