export class CardGenerate {
  /**
   * @private;
   * @type {HTMLElement}
   */
  card;

  /**
   * @param {CardData} cardData
   * @param cardType {'events' | 'archive' | 'media'}
   */
  constructor(cardData, cardType) {
    this.cardData = cardData;
    this.cardType = cardType;

    this.generateCard();
  }

  generateCard() {
    this.card = document.createElement('div');
    this.card.classList.add(`${this.cardType}-card`);

    const top = document.createElement('div');
    top.classList.add(`${this.cardType}-card__top`);

    if (this.cardData.image) {
      top.append(this.generateImage());
    }
    if (this.cardData.type) {
      top.append(this.generateType());
    }

    if (this.cardData.title) {
      top.append(this.generateTitle());
    }

    if (this.cardData.date) {
      top.append(this.generateDate());
    }

    if (this.cardData.price) {
      top.append(this.generatePrice());
    }

    const bottom = document.createElement('div');
    bottom.classList.add(`${this.cardType}-card__bottom`);

    if (
      this.cardData.link &&
      (this.cardType === 'archive' || this.cardType === 'media')
    ) {
      bottom.append(this.generateLink());
    }

    if (this.cardType === 'media' && this.cardData.mediaType === 'audio') {
      this.card.classList.add('media-card_light');
      bottom.append(this.generateAudio());
    }

    if (this.cardType === 'events') {
      bottom.append(
        this.generateTicketButton('Купить билет', this.cardData.ticket)
      );
      bottom.append(
        this.generateTicketButton(
          'Оплатить Пушкинской картой',
          this.cardData.ticket2
        )
      );
    }

    this.card.append(top, bottom);
  }

  generateImage() {
    const imageA = document.createElement('a');
    imageA.classList.add(`${this.cardType}-card__image`);
    imageA.href = this.cardData.link;

    const imageImg = document.createElement('img');
    imageImg.src = this.cardData.image;
    imageImg.alt = this.cardData.title;
    imageImg.srcset = `${this.cardData.image} 1x, ${this.cardData.image2x} 2x`;
    imageA.append(imageImg);

    const imageEffect = document.createElement('span');
    imageEffect.classList.add(`${this.cardType}-card__image-effect`);
    imageA.append(imageEffect);

    if (this.cardData.tagsList?.length) {
      imageA.append(this.generateTagsList());
    }

    return imageA;
  }

  generateTagsList() {
    const tagList = document.createElement('div');
    tagList.classList.add(`${this.cardType}-card__tag-list`);
    this.cardData.tagsList.forEach((item) => {
      const tag = document.createElement('div');
      tag.classList.add(`${this.cardType}-card__tag`);
      tag.textContent = item;
      tagList.append(tag);
    });
    return tagList;
  }

  generateType() {
    const eventType = document.createElement('div');
    eventType.classList.add(`${this.cardType}-card__type`);
    eventType.textContent = this.cardData.type;

    return eventType;
  }

  generateTitle() {
    const title = document.createElement('a');
    title.classList.add(`${this.cardType}-card__title`);
    title.href = this.cardData.link;

    const titleText = document.createElement('div');
    titleText.classList.add(`${this.cardType}-card__title_text`);
    titleText.textContent = this.cardData.title;
    title.append(titleText);

    return title;
  }

  generateDate() {
    const date = document.createElement('div');
    date.classList.add(`${this.cardType}-card__date`);
    date.textContent = this.cardData.date;

    return date;
  }

  generatePrice() {
    const price = document.createElement('div');
    price.classList.add(`${this.cardType}-card__price`);
    price.textContent = this.cardData.price;
    return price;
  }

  generateLink() {
    const link = document.createElement('a');
    link.classList.add('button');
    link.classList.add(`${this.cardType}-card__link`);
    link.href = this.cardData.link;

    const linkText = document.createElement('div');
    linkText.classList.add(`${this.cardType}-card__link_text`);
    if (this.cardType === 'media' && this.cardData.mediaType === 'video') {
      linkText.textContent = 'Смотреть';
    } else if (this.cardType === 'media') {
      linkText.textContent = 'Читать';
    } else {
      linkText.textContent = 'Подробнее';
    }
    link.append(linkText);

    link.append(this.generateButtonIcon('link', 'arrow-right-white'));

    return link;
  }

  /**
   *
   * @param textContent {string}
   * @param ticket {boolean}
   * @return {HTMLDivElement}
   */
  generateTicketButton(textContent, ticket) {
    const button = document.createElement('div');
    button.classList.add('button');
    button.classList.add(`${this.cardType}-card__button`);
    button.classList.add('button-buy-ticket');
    button.type = 'button';
    if (!this.cardData.ticket) {
      button.classList.add('disabled');
      button.disabled = true;
    }

    const buttonText = document.createElement('div');
    buttonText.classList.add(`${this.cardType}-card__button_text`);
    buttonText.textContent = textContent;
    button.append(buttonText);

    button.append(this.generateButtonIcon('button', 'arrow-right'));

    return button;
  }

  /**
   *
   * @param iconType {'link' | 'button'}
   * @param icon {string}
   * @return {HTMLDivElement}
   */
  generateButtonIcon(iconType, icon) {
    const buttonIcon = document.createElement('div');
    buttonIcon.classList.add(`${this.cardType}-card__${iconType}_icon`);

    const buttonIconImg = document.createElement('img');
    buttonIconImg.src = `assets/img/icons/${icon}.svg`;
    buttonIcon.append(buttonIconImg);

    return buttonIcon;
  }

  generateAudio() {
    const audioPlayer = document.createElement('div');
    audioPlayer.classList.add('audio-player');

    const audio = document.createElement('audio');
    audio.src = this.cardData.audio;

    const audioPlayerTime = document.createElement('div');
    audioPlayerTime.classList.add('audio-player__time');

    const audioPlayerTimeCurrent = document.createElement('div');
    audioPlayerTimeCurrent.classList.add('audio-player__time-current');
    audioPlayerTimeCurrent.textContent = '00:00';

    const audioPlayerTimeRangeWrapper = document.createElement('div');
    audioPlayerTimeRangeWrapper.classList.add(
      'audio-player__time-range__wrapper'
    );

    const audioPlayerTimeRange = document.createElement('input');
    audioPlayerTimeRange.classList.add('audio-player__time-range');
    audioPlayerTimeRange.type = 'range';
    audioPlayerTimeRange.max = '100';
    audioPlayerTimeRange.value = '0';

    const audioPlayerTimeRangeBuffer = document.createElement('div');
    audioPlayerTimeRangeBuffer.classList.add(
      'audio-player__time-range__buffer'
    );

    const audioPlayerTimeTotal = document.createElement('div');
    audioPlayerTimeTotal.classList.add('audio-player__time-total');

    const audioPlayerPlay = document.createElement('button');
    audioPlayerPlay.classList.add('audio-player__play');
    audioPlayerPlay.setAttribute('type', 'button');

    const audioPlayerPlayPlay = document.createElement('img');
    audioPlayerPlayPlay.classList.add('audio-player__play_play');
    audioPlayerPlayPlay.src = 'assets/img/icons/play.svg';
    audioPlayerPlay.append(audioPlayerPlayPlay);

    const audioPlayerPlayPause = document.createElement('img');
    audioPlayerPlayPause.classList.add('audio-player__play_pause');
    audioPlayerPlayPause.src = 'assets/img/icons/pause.svg';
    audioPlayerPlay.append(audioPlayerPlayPause);

    audioPlayerTimeRangeWrapper.append(
      audioPlayerTimeRange,
      audioPlayerTimeRangeBuffer
    );
    audioPlayerTime.append(
      audioPlayerTimeCurrent,
      audioPlayerTimeRangeWrapper,
      audioPlayerTimeTotal
    );
    audioPlayer.append(audio);
    audioPlayer.append(audioPlayerTime);
    audioPlayerPlay.append(audioPlayerPlayPlay, audioPlayerPlayPause);
    audioPlayer.append(audioPlayerPlay);
    return audioPlayer;
  }

  /**
   *
   * @return {HTMLElement}
   */
  getCard() {
    return this.card;
  }
}

/**
 * Интерфейс для параметра объекта cardData.
 * @typedef {Object} CardData
 * @property {string} image - URL изображения.
 * @property {string} image2x - URL высокого разрешения изображения.
 * @property {string} type - Тип события или выставки или медиа.
 * @property {string} title - Название события или выставки.
 * @property {string} date - Даты проведения события или выставки.
 * @property {string} price - Стоимость билета события или выставки.
 * @property {string} link - Ссылка на страницу события или выставки.
 * @property {boolean} ticket - Доступна ли покупка билета.
 * @property {boolean} ticket2 - Доступна ли покупка билета Пушкинской картой.
 * @property {string} audio - Ссылка на аудиофайл.
 * @property {'news' | 'article' | 'audio' | 'video'} mediaType - Тип медиа.
 * @property {string[]} tagsList - Список тегов.
 */
