import { AudioPlayer } from '../../components';

class EventAudio {
  init() {
    document.querySelectorAll('.audio-player').forEach((el) => {
      new AudioPlayer(el);
    });
  }
}

export const eventAudio = new EventAudio();
