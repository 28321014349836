export class AudioPlayer {
  /**
   * @type {HTMLElement}
   */
  player;
  /**
   * @type {HTMLInputElement}
   */
  range;
  /**
   * @type {HTMLAudioElement}
   */
  audio;
  /**
   * @type {HTMLElement}
   */
  currentTime;
  /**
   * @type {HTMLElement}
   */
  totalTime;
  /**
   * @type {HTMLElement}
   */
  buttonPlay;
  /**
   * @type {'pause' | 'play'}
   */
  playState = 'play';
  /**
   * @type {number}
   */
  playAnimationFrame;
  handlerPlayAnimationFrame;
  /**
   * @type {Event}
   */
  inputEvent;

  /**
   *
   * @param player {HTMLElement}
   */
  constructor(player) {
    this.player = player;
    this._eventHandlers = new Map();
    this.init();
  }

  init() {
    this.range = this.player.querySelector('.audio-player__time-range');
    this.audio = this.player.querySelector('audio');
    this.currentTime = this.player.querySelector('.audio-player__time-current');
    this.totalTime = this.player.querySelector('.audio-player__time-total');
    this.buttonPlay = this.player.querySelector('.audio-player__play');

    this.initPlayer();
    this.initRange();
    this.initButtonPlay();
    this.initEndedPlay();

    this.inputEvent = new Event('input');
  }

  initBuffer() {
    this.audio.addEventListener('progress', () => {
      if (this.audio.buffered?.length > 0) {
        const bufferedAmount = Math.floor(
          this.audio.buffered.end(this.audio.buffered.length - 1)
        );
        this.player.querySelector(
          '.audio-player__time-range__buffer'
        ).style.width = `${(bufferedAmount / this.range.max) * 100}%`;
      }
    });
  }

  initPlayer() {
    if (this.audio.readyState > 0) {
      this.totalTime.textContent = this.calculateTime(this.audio.duration);
      this.range.max = Math.floor(this.audio.duration).toString();
      this.initBuffer();
    } else {
      this.audio.addEventListener('loadedmetadata', () => {
        this.totalTime.textContent = this.calculateTime(this.audio.duration);
        this.range.max = Math.floor(this.audio.duration).toString();
        this.initBuffer();
      });
    }
  }

  initButtonPlay() {
    this.buttonPlay.addEventListener('click', () => {
      if (this.playState === 'play') {
        this.audio.play();
        this.handlerPlayAnimationFrame = this.playAnimation.bind(this);
        this.buttonPlay.classList.add('audio-player__play_playing');
        requestAnimationFrame(this.handlerPlayAnimationFrame);
        this.playState = 'pause';
        this.emitEvent('onPlay', {});
      } else {
        this.pause();
      }
    });
  }

  /**
   * @public
   */
  pause() {
    this.audio.pause();
    cancelAnimationFrame(this.playAnimationFrame);
    this.playState = 'play';
    this.emitEvent('onPause', {});
    this.buttonPlay.classList.remove('audio-player__play_playing');
  }

  initRange() {
    this.range.addEventListener('input', (event) => {
      const tempSliderValue = event.target.value;
      const progress = (tempSliderValue / this.range.max) * 100;

      this.range.style.background = `linear-gradient(to right, #3272a0 ${progress}%, transparent ${progress}%)`;
      this.currentTime.textContent = this.calculateTime(this.range.value);

      if (!this.audio.paused) {
        cancelAnimationFrame(this.playAnimationFrame);
      }
    });

    this.range.addEventListener('change', () => {
      this.audio.currentTime = parseFloat(this.range.value);
      if (!this.audio.paused) {
        this.handlerPlayAnimationFrame = this.playAnimation.bind(this);
        requestAnimationFrame(this.handlerPlayAnimationFrame);
      }
    });
  }

  initEndedPlay() {
    this.audio.addEventListener('ended', () => {
      cancelAnimationFrame(this.playAnimationFrame);
      this.playState = 'play';
      this.buttonPlay.classList.remove('audio-player__play_playing');
    });
  }

  playAnimation() {
    this.range.value = Math.floor(this.audio.currentTime).toString();
    this.range.dispatchEvent(this.inputEvent);
    this.currentTime.textContent = this.calculateTime(this.range.value);
    this.handlerPlayAnimationFrame = this.playAnimation.bind(this);
    this.playAnimationFrame = requestAnimationFrame(
      this.handlerPlayAnimationFrame
    );
  }

  calculateTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  on(event, handler) {
    if (!this._eventHandlers.has(event)) {
      this._eventHandlers.set(event, []);
    }
    this._eventHandlers.get(event).push(handler);
  }

  emitEvent(event, data) {
    if (this._eventHandlers.has(event)) {
      const handlers = this._eventHandlers.get(event);
      handlers.forEach((handler) => {
        handler(data);
      });
    }
  }
}
