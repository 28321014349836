// import '../scss/app.scss';
// import 'swiper/swiper-bundle.css';
import {
  autoPopup,
  burger,
  cardTitle,
  eventsAccordion,
  faq,
  footer,
  header,
  popup,
  popupPage,
  scrollButtons,
} from './components';
import { home } from './pages/home';
import { filmStudio } from './pages/film-studio';
import { about } from './pages/about';
import { Archive } from './pages/archive';
import { Events } from './pages/events';
import { Exhibitions } from './pages/exhibitions';
import { BuyTickets } from './pages/buy-tickets';
import { Media } from './pages/media';
import { eventPage } from './pages/event';
import { search } from './components/search';
import { isSafari } from './utils/user-agent.util';
import { mediaPage } from './pages/media/media-page';
import { documents } from './pages/documents';

window.addEventListener('DOMContentLoaded', () => {
  init();
});

function init() {
  /* Global */
  burger.init();
  popup.init();
  eventsAccordion.init();
  header.init();
  footer.init();
  cardTitle.init();
  popupPage.init();
  faq.init();
  search.init();
  autoPopup.init();
  scrollButtons.init();

  /* Pages */
  home.init();
  filmStudio.init();
  about.init();
  mediaPage.init();
  documents.init();
  if (document.querySelector('.archive-page')) {
    new Archive();
  }
  if (document.querySelector('.events-page')) {
    new Events();
  }
  if (document.querySelector('.exhibitions-page')) {
    new Exhibitions();
  }
  if (document.querySelector('.buy-tickets-page')) {
    new BuyTickets();
  }
  if (document.querySelector('.media-page')) {
    new Media();
  }
  eventPage.init();

  if (isSafari()) {
    document.body.classList.add('safari-browser');
  }
}

window.onbeforeunload = function () {
  if (document.querySelector('.home-page')) {
    window.scrollTo(0, 0);
  }
};