import { aboutHero } from './hero';
import { images } from './images';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { aboutAuthor } from './about-author';

class About {
  init() {
    if (!document.querySelector('.about-page')) {
      return;
    }
    gsap.registerPlugin(ScrollTrigger);

    aboutHero.init();
    images.init();
    aboutAuthor.init();

    this.background();
  }

  background() {
    const pageBackground = document.querySelector('.about-page');
    const header = document.querySelector('.header');
    const search = document.querySelector('.search');
    gsap.to(pageBackground, {
      scrollTrigger: {
        trigger: '.about-partners',
        start: 'top 50%',
        end: 'top 30%',
        markers: false,
        scrub: true,
        onEnter: () => {
          pageBackground.classList.remove('invert');
          header.classList.remove('invert');
          search.classList.remove('invert');
        },
        onLeaveBack: () => {
          pageBackground.classList.add('invert');
          header.classList.add('invert');
          search.classList.add('invert');
        },
      },
      backgroundColor: '#000000',
    });
  }
}

export const about = new About();
