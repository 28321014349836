import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class Timeline {
  init() {
    gsap.registerPlugin(ScrollTrigger);
    const items = document.querySelectorAll('.timeline-item');
    items.forEach((item) => {
      this.images(item);
      this.years(item);
    });
  }

  /**
   *
   * @param item {HTMLElement}
   */
  images(item) {
    const image = item.querySelector('.timeline-item__image img');

    gsap.to(image, {
      scrollTrigger: {
        trigger: item,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        markers: false,
      },
      translateY: -80,
    });
  }

  /**
   *
   * @param item {HTMLElement}
   */
  years(item) {
    const year = item.querySelector('.timeline-item__year');

    gsap.to(year, {
      scrollTrigger: {
        trigger: item,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        markers: false,
      },
      y: 100,
    });
  }
}

export const timeline = new Timeline();
