class Documents {
  init() {
    const documentPage = document.querySelector('.document-page');
    if (!documentPage) {
      return;
    }

    document.querySelector('.header').classList.add('invert');
    document.querySelector('.search').classList.add('invert');
  }
}

export const documents = new Documents();
