import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { isTabletWidth } from '../../utils';
import { gsap } from 'gsap';

class EventSlider {
  init() {
    const slider = document.querySelector('.event-slider');
    if (!slider) {
      return;
    }

    Swiper.use([Navigation]);

    const next = slider.querySelector('.event-slider__next');
    const prev = slider.querySelector('.event-slider__prev');

    const swiper = new Swiper(slider.querySelector('.swiper'), {
      spaceBetween: 20,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      },
    });

    const cursor = slider.querySelector('.event-slider__cursor');

    if (!cursor || isTabletWidth()) {
      return;
    }

    slider.addEventListener('mouseenter', () => {
      cursor.classList.add('event-slider__cursor_show');
    });

    slider.addEventListener('mouseleave', () => {
      cursor.classList.remove('event-slider__cursor_show');
    });

    prev.addEventListener('mouseenter', () => {
      cursor.classList.remove('event-slider__cursor_rotate');
    });

    next.addEventListener('mouseenter', () => {
      cursor.classList.add('event-slider__cursor_rotate');
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isTabletWidth()) {
        gsap.to(cursor, {
          duration: 0.4,
          x: e.pageX - cursor.clientWidth / 2,
          y: e.clientY - cursor.clientHeight / 2,
        });
      }
    });
  }
}

export const eventSlider = new EventSlider();
