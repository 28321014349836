import { popup } from './popup';

class AutoPopup {
  init() {
    const autoPopup = document.querySelector('.popup[data-open="true"]');
    const isHomePage = !!document.querySelector('.home-page');

    if (!autoPopup || !isHomePage) {
      return;
    }

    setTimeout(() => {
      popup.open(autoPopup);
    });
  }
}

export const autoPopup = new AutoPopup();
