import { homeHeroHeight } from './home-hero-height';
import { homeBackground } from './home-background';
import { homeBarrel } from './home-barrel';

class Home {
  init() {
    if (!document.querySelector('.home-page')) {
      return;
    }

    homeHeroHeight.init();
    homeBackground.init();
    homeBarrel.init();
  }
}

export const home = new Home();
