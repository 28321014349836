import { isFullUrl } from '../utils';
import { SelectCheckbox } from './select-checkbox';
import { DatePicker } from './date-picker';

export class FilterHandler {
  /**
   * @type {HTMLElement}
   */
  loadMoreButton;
  /**
   *
   * @type {SelectCheckbox[]}
   */
  selectCheckboxes = [];
  /**
   *
   * @type {DatePicker[]}
   */
  selectDatePickers = [];
  /**
   * @type {URLSearchParams}
   */
  urlParams;
  /**
   * @type {HTMLInputElement}
   */
  countInput;
  /**
   * @type {HTMLElement}
   */
  emptyText;
  constructor(formSelector, url, container) {
    this.form = document.querySelector(formSelector);
    this.container = document.querySelector(container);
    if (!this.form || !this.container) {
      return;
    }
    this.url = isFullUrl(url) ? url : window.location.origin + url;
    this.form.addEventListener('submit', this.handleSubmit.bind(this));
    this.loadMoreButton = document.querySelector('.button_load-more');

    this.emptyText = document.querySelector('.empty-text');

    this.initializeSelectCheckboxes();
    this.initializeDatePickers();

    this.countInput = document.querySelector('input[name="count"]');

    this.checkQueryParams();
  }

  initializeSelectCheckboxes() {
    document.querySelectorAll('.select-checkbox').forEach((el) => {
      this.selectCheckboxes.push(new SelectCheckbox(el));
    });
  }

  initializeDatePickers() {
    document.querySelectorAll('.datepicker').forEach((el) => {
      this.selectDatePickers.push(new DatePicker(el));
    });
  }

  checkQueryParams() {
    this.urlParams = new URLSearchParams(window.location.search);

    this.selectCheckboxes.forEach((checkbox) => {
      const checkboxInputs = checkbox.select.querySelectorAll('input');
      if (this.urlParams.has(checkboxInputs[0].name)) {
        const values = this.urlParams
          .get(checkboxInputs.item(0).name)
          .split(',');
        checkboxInputs.forEach((el) => {
          if (values.includes(el.value)) {
            checkbox.selectCheckbox(el);
          }
        });
      }
    });

    this.selectDatePickers.forEach((datePiker) => {
      const datePickerInput = datePiker.select.querySelector('input');
      if (this.urlParams.has(datePickerInput.name)) {
        datePiker.setValue(this.urlParams.get(datePickerInput.name));
      }
    });

    if (this.countInput && this.urlParams.has(this.countInput.name)) {
      this.countInput.value = this.urlParams.get(this.countInput.name);
    }

    this.handleFormSubmit();
  }

  /**
   *
   * @param event {FormDataEvent}
   */
  handleSubmit(event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.handleFormSubmit();
  }

  handleFormSubmit() {
    const formData = new FormData(this.form);
    const queryParams = new URLSearchParams();
    const data = {};

    formData.forEach((value, key) => {
      if (value.trim() === '') return;
      if (!data[key]) {
        data[key] = [];
      }
      data[key].push(value);
    });

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        queryParams.append(key, data[key].join(','));
      }
    }

    const finalUrl = `${this.url}?${queryParams.toString()}`;

    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('?')[0];
    const newUrl = `${baseUrl}?${queryParams.toString()}`;

    history.pushState(null, null, newUrl);

    this.container.append(this.generateLoading());

    this.loadMoreButton?.classList.add('is-loading');
    this.submitRequest(finalUrl);
  }

  submitRequest(url) {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.checkEmpty(data.data);
        return this.handleResponse(data);
      })
      .catch((error) => console.error('Ошибка при выполнении запроса:', error));
  }

  checkLoadMore(count, totalCount) {
    if (count >= totalCount && this.loadMoreButton) {
      this.loadMoreButton.style.display = 'none';
    } else {
      this.loadMoreButton.style.removeProperty('display');
    }
  }

  /**
   *
   * @return {HTMLDivElement}
   */
  generateLoading() {
    const loading = document.createElement('div');
    loading.classList.add('loading');
    const icon = document.createElement('img');
    icon.src = '../../assets/img/icons/loading.svg';
    loading.append(icon);
    return loading;
  }

  checkEmpty(data) {
    if (!data.length) {
      this.showEmptyText();
    } else {
      this.hideEmptyText();
    }
  }

  showEmptyText() {
    this.emptyText?.classList.add('show');
  }

  hideEmptyText() {
    this.emptyText?.classList.remove('show');
  }

  handleResponse(data) {}
}
