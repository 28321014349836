import { isMobileWidth } from '../utils';

class CardTitle {
  init() {
    if (isMobileWidth()) {
      return;
    }
    document
      .querySelectorAll('.events-card, .archive-card, .media-card')
      .forEach((el) => {
        let titlePrefix = 'events-card';
        if (el.classList.contains('archive-card')) {
          titlePrefix = 'archive-card';
        }
        if (el.classList.contains('media-card')) {
          titlePrefix = 'media-card';
        }

        let clone;
        let timeout;
        const title = el.querySelector(`.${titlePrefix}__title`);

        el.addEventListener('mouseenter', () => {
          clearTimeout(timeout);
          if (!title.querySelector(`.${titlePrefix}__title_underline`)) {
            clone = document.createElement('div');
            this.createClone(clone, title, titlePrefix);
            title.append(clone);
          }
          title.classList.add(`${titlePrefix}__title_animated`);
        });

        el.addEventListener('mouseleave', () => {
          title.classList.remove(`${titlePrefix}__title_animated`);
          timeout = setTimeout(() => {
            clone?.remove();
          }, 150);
        });
      });
  }

  /**
   *
   * @param clone {HTMLElement}
   * @param title {HTMLElement}
   * @param titlePrefix {string}
   */
  createClone(clone, title, titlePrefix) {
    clone.classList.add(`${titlePrefix}__title_clone`);
    const underline = document.createElement('span');
    underline.classList.add(`${titlePrefix}__title_underline`);
    underline.textContent = title.querySelector(
      `.${titlePrefix}__title_text`
    ).textContent;
    clone.append(underline);
  }
}
export const cardTitle = new CardTitle();
