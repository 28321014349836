import { Select } from './select';

export class SelectCheckbox extends Select {
  /**
   * @type {EventListener[]}
   */
  checkboxClickHandlers = [];

  /**
   *
   * @param select {HTMLElement}
   */
  constructor(select) {
    super(select);
    this.initCheckbox();
  }

  /**
   * @private
   */
  initCheckbox() {
    this.dropdown
      .querySelectorAll('.select-checkbox__item')
      .forEach((el, i) => {
        this.checkboxClickHandlers.push(this.checkboxClick.bind(this));
        el.addEventListener('click', this.checkboxClickHandlers[i]);
      });
  }

  /**
   *
   * @private
   * @param e {MouseEvent}
   */
  checkboxClick(e) {
    e.preventDefault();
    const item = e.target.closest('.select-checkbox__item');
    const input = item.querySelector('input');
    this.selectCheckbox(input);

    input.form?.dispatchEvent(new Event('submit'));

    if (this.selectType === 'radio') {
      this.closeDropdown();
    }
  }

  /**
   *
   * @param input {HTMLInputElement}
   */
  selectCheckbox(input) {
    if (this.selectType === 'radio') {
      this.dropdown
        .querySelector('.select-checkbox__item_active')
        ?.classList.remove('select-checkbox__item_active');
    }

    const item = input.parentElement;

    if (
      this.selectType === 'checkbox' &&
      item.classList.contains('select-checkbox__item_active')
    ) {
      item.classList.remove('select-checkbox__item_active');
      input.checked = false;
    } else {
      item.classList.add('select-checkbox__item_active');
      input.checked = true;
    }

    const labels = [];

    this.dropdown
      .querySelectorAll('.select-checkbox__item_active')
      .forEach((el) => {
        labels.push(el.querySelector('.select-checkbox__label').textContent);
      });

    this.selectText.textContent = labels.join(', ');
  }

  /**
   * @public
   */
  destroy() {
    this.select.removeEventListener('click', this.selectClickHandler);
    this.dropdown
      .querySelectorAll('.select-checkbox__item')
      .forEach((el, i) => {
        el.removeEventListener('click', this.checkboxClickHandlers[i]);
      });
    document.removeEventListener('click', this.outsideClickHandler);
  }
}
