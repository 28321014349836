export const EVENTS_URL =
  'https://test.dsml/tours';
export const EXHIBITIONS_URL =
  'https://test.dsml/exhibitions';
export const MEDIA_URL =
  'https://test.dsml/medias';
export const TICKETS_URL =
  'https://test.dsml/buy_tickets';
export const ARCHIVE_URL =
  'https://test.dsml/archives';
export const SEARCH_URL =
  'https://5a3c0422-5056-4a56-a7dd-07d57fa2796b.mock.pstmn.io/search';
